import React from 'react';
import { Formik, Form, Field } from 'formik';
import { navigate } from 'gatsby';
import qs from 'query-string';
import axios from 'axios';
import PropTypes from 'prop-types';
import { TextField } from '../form/TextField';

function changePassword({ password, token }) {
	return axios.get(
		`/.netlify/functions/change-password?${qs.stringify({
			token,
			password,
		})}`
	);
}

export default function ChangePasswordForm({ token }) {
	const initialValues = {
		password: '',
		confirmPassword: '',
	};

	const validate = (values) => {
		const errors = {};
		if (!values.password) {
			errors.password = 'Kötelező mező';
		} else if (values.password.length < 6) {
			errors.password = 'Legalább 6 karakter legyen';
		}
		if (!values.confirmPassword) {
			errors.confirmPassword = 'Kötelező mező';
		} else if (values.confirmPassword !== values.password) {
			errors.confirmPassword = 'Jelszó nem egyezik';
		}
		return errors;
	};

	const onSubmit = async (values, { setSubmitting }) => {
		const { password } = values;
		await changePassword({ password, token });
		setSubmitting(false);
		navigate('/success', { state: { type: 'changePassword' } });
	};

	return (
		<Formik
			initialValues={initialValues}
			validate={validate}
			onSubmit={onSubmit}
		>
			{({ isSubmitting }) => (
				<Form>
					<Field
						type="password"
						name="password"
						label="Új Jelszó"
						component={TextField}
					/>
					<Field
						type="password"
						name="confirmPassword"
						label="Jelszó megerősítés"
						component={TextField}
					/>

					<button
						type="submit"
						className="btn btn-light my-3"
						disabled={isSubmitting}
					>
						Jelszót változtatok
					</button>
				</Form>
			)}
		</Formik>
	);
}

ChangePasswordForm.propTypes = {
	token: PropTypes.string.isRequired,
};
