import React from 'react';

function Icon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="301"
			height="264"
			viewBox="0 0 301 264"
		>
			<defs>
				<path id="a" d="M0 132.767L102.992 132.767 102.992 0 0 0z" />
			</defs>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<g transform="translate(-772 -237) translate(252 128) translate(521 110) translate(0 101)">
					<path
						stroke="#E5E5E5"
						strokeWidth="2"
						d="M90 94L101 94 101 73 90 73z"
					/>
					<path
						stroke="#E5E5E5"
						strokeWidth="2"
						d="M80 132L110 132 110 94 80 94z"
					/>
					<path
						stroke="#E5E5E5"
						strokeWidth="2"
						d="M142 94L153 94 153 73 142 73z"
					/>
					<path
						stroke="#E5E5E5"
						strokeWidth="2"
						d="M133 132L163 132 163 94 133 94z"
					/>
					<path
						stroke="#E5E5E5"
						strokeWidth="2"
						d="M45 46L71 46 71 34 45 34z"
					/>
					<path
						stroke="#E5E5E5"
						strokeWidth="2"
						d="M110 132L110 82 133 82 133 132"
					/>
					<path
						stroke="#E5E5E5"
						strokeWidth="2"
						d="M115 82L129 82 129 60 115 60z"
					/>
					<path
						stroke="#E5E5E5"
						strokeLinecap="round"
						strokeWidth="2"
						d="M122 59V41"
					/>
					<path stroke="#E5E5E5" strokeWidth="2" d="M54 34L54 24 63 24 63 34" />
					<path
						stroke="#E5E5E5"
						strokeWidth="2"
						d="M48 60v-4M59 60v-4M70 60v-4M48 72v-4M59 72v-4M70 72v-4M48 85v-4M59 85v-4M70 85v-4M48 97v-4M59 97v-4M70 97v-4M48 110v-4M59 110v-4M70 110v-4M48 122v-4M59 122v-4M70 122v-4"
					/>
					<g stroke="#E5E5E5" strokeWidth="2" transform="translate(0 46)">
						<path d="M36.7637363 86.6978155L36.7637363 0.363212069 80.3807143 0.363212069 80.3807143 86.6978155" />
						<path strokeLinecap="round" d="M.5 86.698h298.196" />
					</g>
					<g transform="translate(170)">
						<path
							stroke="#E5E5E5"
							strokeLinecap="round"
							strokeWidth="2"
							d="M82 92.922L87.801 92.922 87.801 74 82 74z"
						/>
						<path
							stroke="#E5E5E5"
							strokeLinecap="round"
							strokeWidth="2"
							d="M82 120.922L87.801 120.922 87.801 102 82 102z"
						/>
						<path
							stroke="#E5E5E5"
							strokeLinecap="round"
							strokeWidth="2"
							d="M14 92.922L19.801 92.922 19.801 74 14 74z"
						/>
						<path
							stroke="#E5E5E5"
							strokeLinecap="round"
							strokeWidth="2"
							d="M14 120.922L19.801 120.922 19.801 102 14 102z"
						/>
						<path
							stroke="#E5E5E5"
							strokeLinecap="round"
							strokeWidth="2"
							d="M84.866 36.727L80.933 33 77 36.727 77 44.613"
						/>
						<path
							stroke="#E5E5E5"
							strokeLinecap="round"
							strokeWidth="2"
							d="M85 45.337L85 36.727 88.934 33 92.866 36.727 92.866 44.613"
						/>
						<g transform="translate(0 .767)">
							<path
								stroke="#E5E5E5"
								strokeLinecap="round"
								strokeWidth="2"
								d="M50.683 62L28 78.571 28 131.755 73.365 131.755 73.365 78.571z"
							/>
							<path
								stroke="#E5E5E5"
								strokeLinecap="round"
								strokeWidth="2"
								d="M58.823 106.985V90.961c0-2.976-.754-5.94-2.388-8.428-1.104-1.683-2.708-3.36-5.023-4.533-2.317 1.173-3.919 2.85-5.025 4.533C44.754 85.021 44 87.985 44 90.961v16.024M44 131.379v-11.672A2.707 2.707 0 0146.706 117h9.411a2.707 2.707 0 012.706 2.707v11.672"
							/>
							<mask id="b" fill="#fff">
								<use xlinkHref="#a" />
							</mask>
							<path
								stroke="#E5E5E5"
								strokeWidth="2"
								d="M77 62.006L92.698 62.006 92.698 52 77 52z"
								mask="url(#b)"
							/>
							<path
								stroke="#E5E5E5"
								strokeLinecap="round"
								strokeWidth="2"
								d="M77 44L77 51.625 92.698 51.625 92.698 44"
								mask="url(#b)"
							/>
							<path
								stroke="#E5E5E5"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M92.716 36.48L92.716 21 77 21 77 36.48"
								mask="url(#b)"
							/>
							<path
								stroke="#E5E5E5"
								strokeLinecap="round"
								strokeWidth="2"
								d="M92.732 20.957L84.866 4 77 20.957"
								mask="url(#b)"
							/>
							<path
								stroke="#E5E5E5"
								strokeLinecap="round"
								strokeWidth="2"
								d="M17.866 36.727L13.933 33 10 36.727 10 44.613"
								mask="url(#b)"
							/>
							<path
								stroke="#E5E5E5"
								strokeLinecap="round"
								strokeWidth="2"
								d="M18 45.337L18 36.727 21.934 33 25.866 36.727 25.866 44.613"
								mask="url(#b)"
							/>
							<path
								stroke="#E5E5E5"
								strokeWidth="2"
								d="M10 62.006L25.698 62.006 25.698 52 10 52z"
								mask="url(#b)"
							/>
							<path
								stroke="#E5E5E5"
								strokeLinecap="round"
								strokeWidth="2"
								d="M10 44L10 51.625 25.698 51.625 25.698 44"
								mask="url(#b)"
							/>
							<path
								stroke="#E5E5E5"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M25.716 36.48L25.716 21 10 21 10 36.48"
								mask="url(#b)"
							/>
							<path
								stroke="#E5E5E5"
								strokeLinecap="round"
								strokeWidth="2"
								d="M25.732 20.957L17.866 4 10 20.957"
								mask="url(#b)"
							/>
							<path
								stroke="#E5E5E5"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M51.004 62.006L46 57.003 51.004 52 56.007 57.003z"
								mask="url(#b)"
							/>
							<path
								stroke="#E5E5E5"
								strokeLinecap="round"
								strokeWidth="2"
								d="M95.595 62h3.087c2.104 0 3.355 2.351 2.18 4.096l-5.267 7.818v57.841H74V62h21.595zM6.72 62H3.63c-2.104 0-3.354 2.351-2.179 4.096l5.267 7.818v57.841h21.594V62H6.72zM51 88v18.931"
								mask="url(#b)"
							/>
						</g>
					</g>
				</g>
				<g
					stroke="#E5E5E5"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				>
					<path
						d="M45.738 33.415c0-7.317-5.877-13.25-13.128-13.25-7.25 0-13.128 5.933-13.128 13.25 0 7.318 5.878 13.25 13.128 13.25s13.128-5.932 13.128-13.25z"
						transform="translate(-772 -237) translate(252 128) translate(521 110) translate(111)"
					/>
					<path
						d="M39.718 33.415c0-3.961-3.182-7.173-7.108-7.173-3.925 0-7.107 3.212-7.107 7.173 0 3.962 3.182 7.173 7.108 7.173 3.925 0 7.107-3.211 7.107-7.173zM32.61 14.67V.3M38.963 15.8l2.734-7.582M20.672 19.055L11.52 8.048M26.258 15.8l-2.735-7.582M14.32 30.16L.297 27.665M16.525 24.042l-6.924-4.034M16.525 42.788l-12.33 7.185M14.32 36.67l-7.875 1.401M26.258 51.03l-4.87 13.502M20.672 47.775l-5.14 6.181M38.963 51.03l4.87 13.502M32.61 52.16v8.07M48.695 42.788l12.33 7.185M44.549 47.775l5.139 6.181M50.901 30.16l14.021-2.495M50.901 36.67l7.874 1.401M44.549 19.055L53.7 8.048M48.695 24.042l6.924-4.034"
						transform="translate(-772 -237) translate(252 128) translate(521 110) translate(111)"
					/>
				</g>
				<path
					stroke="#E5E5E5"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M95.904 114.596c0-11.798-9.665-21.397-21.55-21.397h-.402l-.183-.372c-3.491-9.416-12.619-15.743-22.71-15.743-10.738 0-20.302 7.16-23.249 17.41l-.153.537-.54-.139a9.932 9.932 0 00-2.42-.283c-5.367 0-9.726 4.178-9.925 9.51l-.017.378-.353.132C8.18 107.03 4 113.108 4 119.759 4 128.716 11.327 136 20.333 136h7.07M272.528 78.912c0-8.317-6.786-15.083-15.131-15.083h-.282l-.128-.262c-2.452-6.638-8.86-11.098-15.945-11.098-7.54 0-14.255 5.047-16.324 12.273l-.107.378-.38-.098a6.947 6.947 0 00-1.7-.2 6.946 6.946 0 00-6.968 6.705l-.012.266-.248.093C210.936 73.579 208 77.863 208 82.55 208 88.866 213.144 94 219.468 94h4.963M178.818 101.07c0-6.576-5.45-11.926-12.15-11.926h-.227l-.103-.208c-1.969-5.248-7.115-8.775-12.804-8.775-6.055 0-11.448 3.99-13.109 9.705l-.086.299-.305-.078a5.661 5.661 0 00-1.364-.158c-3.026 0-5.484 2.33-5.596 5.301l-.01.211-.2.073c-3.507 1.34-5.864 4.726-5.864 8.433 0 4.993 4.131 9.053 9.209 9.053h3.986"
					transform="translate(-772 -237) translate(252 128) translate(521 110)"
				/>
				<g
					fill="#FFF"
					transform="translate(-772 -237) translate(252 128) translate(521 110) translate(49 163)"
				>
					<path
						stroke="#FFF"
						strokeWidth="2.4"
						d="M168.43 71.141v-6.578c0-1.066-.904-1.906-1.976-1.847-8.27.445-15.657-2.302-21.357-5.54-10.46-5.944-22.25-9.176-34.296-9.176H91.248a41.952 41.952 0 00-18.683 4.388l-14.702 7.314c-5.752 2.86-12.024 4.57-18.445 4.92-18.286.994-28.77 4.835-32.836 6.673a3.005 3.005 0 00-1.752 3.07l.507 4.623-4.326 2.828a2.22 2.22 0 00-.886 2.593l2.034 5.834a8.597 8.597 0 006.864 5.663c.42.063.846.094 1.27.094h.803a16.116 16.116 0 01-.491-3.951c0-8.923 7.268-16.154 16.234-16.154 8.965 0 16.234 7.231 16.234 16.154 0 1.364-.17 2.686-.491 3.951h77.994a16.058 16.058 0 01-.49-3.951c0-8.923 7.267-16.154 16.233-16.154 8.967 0 16.234 7.231 16.234 16.154 0 1.364-.171 2.686-.492 3.951h9.115C171.942 96 172 82.217 172 82.217c0-6.521-3.57-11.076-3.57-11.076z"
					/>
					<path
						stroke="#2C1B6F"
						strokeWidth="2"
						d="M46.471 90.846a7.614 7.614 0 10-15.228 0 7.614 7.614 0 0015.228 0zM137.667 90.846a7.615 7.615 0 00-15.228 0 7.615 7.615 0 0015.228 0z"
					/>
					<path
						stroke="#2C1B6F"
						strokeWidth="2"
						d="M156.802 73.342v-5.507c0-.893-.753-1.596-1.646-1.547-6.889.373-13.042-1.927-17.79-4.638-8.713-4.976-18.535-7.682-28.569-7.682H92.51a34.802 34.802 0 00-15.563 3.674L64.7 63.765c-4.791 2.395-10.016 3.826-15.365 4.119-15.232.832-23.965 4.048-27.352 5.587a2.518 2.518 0 00-1.459 2.57l.422 3.87-3.604 2.368a1.864 1.864 0 00-.738 2.17l1.695 4.885a7.17 7.17 0 006.775 4.82h.669a13.557 13.557 0 01-.409-3.308c0-7.47 6.054-13.524 13.523-13.524 7.468 0 13.523 6.054 13.523 13.524 0 1.142-.142 2.249-.409 3.308h64.969a13.509 13.509 0 01-.409-3.308c0-7.47 6.054-13.524 13.523-13.524 7.469 0 13.523 6.054 13.523 13.524 0 1.142-.143 2.249-.41 3.308h7.593c8.968 0 9.016-11.54 9.016-11.54 0-5.46-2.974-9.272-2.974-9.272z"
					/>
					<path
						stroke="#2C1B6F"
						strokeWidth="2"
						d="M103.774 54.968l-2.872 15.547H66.949c-.695 0-.88-.551-.918-.72-.084-.358-.028-5.681-.028-5.681"
					/>
					<path
						stroke="#2C1B6F"
						strokeWidth="2"
						d="M129.7044 58.9736L127.5654 70.5146 106.9334 70.5146 109.7144 54.9756"
					/>
					<path
						stroke="#2C1B6F"
						strokeLinecap="round"
						strokeWidth="2"
						d="M65.102 94.154v-18.52M100.644 94.154v-18.52"
					/>
					<path
						stroke="#2C1B6F"
						strokeWidth="2"
						d="M120.425 21.14c0-11.4-9.24-20.64-20.64-20.64-11.399 0-20.64 9.24-20.64 20.64 0 11.399 9.241 20.64 20.64 20.64 11.4 0 20.64-9.241 20.64-20.64z"
					/>
					<path
						stroke="#2C1B6F"
						strokeWidth="2"
						d="M113.054 37.075l-1.016-4.842c-.256-1.223-1.059-2.147-2.054-2.364H89.586c-.995.217-1.799 1.141-2.054 2.364l-1.016 4.842M105.667 18.578c0 3.52-2.634 6.372-5.883 6.372-3.248 0-5.882-2.853-5.882-6.372 0-3.52 2.634-6.373 5.882-6.373 3.25 0 5.883 2.853 5.883 6.373z"
					/>
				</g>
			</g>
		</svg>
	);
}

export default Icon;
